import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import HomeSection from './components/HomeSection';
import AboutSection from './components/AboutSection';
import ServicesSection from './components/ServicesSection';
import ContactSection from './components/ContactSection';
import ProductSection from './components/ProductSection'; // Import ProductSection
import Footer from './components/Footer'; // Import Footer
import NewComponent from './components/NewComponent'; // Import NewComponent
import logo from './assets/logo.svg'; // Make sure this path matches your logo location
import './index.css'; // Import Tailwind styles

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Show the button when the user scrolls past the middle of the page
      if (window.scrollY > window.innerHeight / 2) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Simulate loading time and hide spinner
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500); // Adjust time as needed

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex flex-col items-center justify-center bg-white bg-opacity-90 z-50">
        <img
          src={logo}
          alt="Logo"
          className="animate-breathing h-32 w-32"
        />
        <h1 className="mt-4 text-2xl font-bold text-gray-800">MediaMentions</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <HomeSection className="animate-fadeIn" />
        <AboutSection className="animate-fadeIn" />
        <NewComponent className="animate-fadeIn" />
        <ServicesSection className="animate-fadeIn" />
        {/* <ProductSection /> Include ProductSection */}
        <ContactSection className="animate-fadeIn" />
      </main>
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 bg-gray-800 text-white p-2 rounded-full shadow-md hover:bg-gray-600 transition-all duration-300"
        >
          &#8593; {/* Up arrow character */}
        </button>
      )}
      <Footer />
    </div>
  );
}

export default App;
