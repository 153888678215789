import React from 'react';

function AboutSection() {
  return (
    <section id="about" className="py-4 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto">
          <div className="inline-block p-2 bg-blue-50 rounded-xl mb-4">
            <svg className="w-8 h-8 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clipRule="evenodd" />
            </svg>
          </div>
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">
            The One Stop Solution for Your Guest Posting Needs
          </h2>
          <p className="text-lg text-gray-700 mb-12 leading-relaxed">
            MediaMentions is a leading link building agency dedicated to helping businesses improve their online visibility and drive organic traffic through strategic link acquisition. We have a team of experienced link building specialists who are passionate about delivering results for our clients.
          </p>
          <div className="grid md:grid-cols-3 gap-8 mt-12">
            {[
              { icon: '🎯', title: 'Strategic Approach', text: 'Targeted link building strategies' },
              { icon: '🚀', title: 'Proven Results', text: 'Measurable improvement in rankings' },
              { icon: '🤝', title: 'Expert Team', text: 'Dedicated specialists at your service' },
            ].map((item, index) => (
              <div key={index} className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
                <div className="text-3xl mb-4">{item.icon}</div>
                <h3 className="font-bold text-xl mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;