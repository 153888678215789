import React, { useState } from 'react';

function ContactSection() {
  const [status, setStatus] = useState({ loading: false, message: '', type: '' });

  const onSubmit = async (event) => {
    event.preventDefault();
    setStatus({ loading: true, message: '' });

    const formData = new FormData(event.target);
    formData.append("access_key", "ac4c2ba1-d986-4370-b293-04625ecc4a49");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });

      const data = await response.json();

      if (data.success) {
        event.target.reset();
        setStatus({
          loading: false,
          message: 'Thank you! Your message has been sent successfully.',
          type: 'success'
        });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Contact form error:', error);
      setStatus({
        loading: false,
        message: error.message || 'Failed to send message. Please try again.',
        type: 'error'
      });
    }
  };

  return (
    
    <section id="contact" className="relative py-24 overflow-hidden">
     
              
      {/* Background gradient circles */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 rounded-full bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-3xl"></div>
        <div className="absolute -right-1/4 -bottom-1/4 w-1/2 h-1/2 rounded-full bg-gradient-to-l from-blue-500/20 to-purple-500/20 blur-3xl"></div>
      </div>

      <div className="container mx-auto px-4 relative">  
     
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Get in Touch
          </h2>
          <p className="text-gray-600 max-w-xl mx-auto">
            Have a question or want to work together? We'd love to hear from you.
          </p>
        </div>

        <div className="max-w-xl mx-auto">
          <div className="backdrop-blur-lg bg-white/80 rounded-2xl shadow-xl p-8 transition-all duration-300 hover:shadow-2xl">
            <form className="space-y-6" onSubmit={onSubmit}>
            
                {/* <input 
                type="hidden" 
                name="replyto" 
                value="frencesunlan09@gmail.com"
                /> */}
              <div className="space-y-4">
                <div>
                  <label htmlFor="name" className="block text-gray-700 text-sm font-medium mb-2">Name</label>
                  
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition-all duration-200 bg-white/50 backdrop-blur-sm"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition-all duration-200 bg-white/50 backdrop-blur-sm"
                    placeholder="Your Email"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-gray-700 text-sm font-medium mb-2">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    rows="5"
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition-all duration-200 bg-white/50 backdrop-blur-sm resize-none"
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                disabled={status.loading}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold py-3 px-6 rounded-lg hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-blue-500/20 disabled:opacity-50 disabled:cursor-not-allowed transform transition-all duration-200 hover:-translate-y-0.5"
              >
                {status.loading ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Sending...
                  </span>
                ) : 'Send Message'}
              </button>

              {status.message && (
                <div className={`mt-4 p-4 rounded-lg animate-fade-in ${status.type === 'success' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                  }`}>
                  {status.message}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;