import React from 'react';

// Dynamic import with fallback
let FaLinkedin, FaTwitter, FaInstagram, FaFacebook;
try {
    const icons = require('react-icons/fa');
    FaLinkedin = icons.FaLinkedin;
    FaTwitter = icons.FaTwitter;
    FaInstagram = icons.FaInstagram;
    FaFacebook = icons.FaFacebook;
} catch (e) {
    // Fallback icon component if module fails to load
    const FallbackIcon = () => <span className="w-6 h-6 inline-block text-center">●</span>;
    FaLinkedin = FaTwitter = FaInstagram = FallbackIcon;
}

const Footer = () => {
    const handleNavLinkClick = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <footer className="bg-gray-900 text-white py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-xl font-bold mb-4">MediaMentions</h3>
                        <p className="text-gray-400">
                            Your trusted partner in building high-quality backlinks and improving your online presence.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
                        <ul className="space-y-2">
                            <li>
                                <button
                                    onClick={() => handleNavLinkClick('home')}
                                    className="text-gray-400 hover:text-white cursor-pointer"
                                >
                                    Home
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleNavLinkClick('services')}
                                    className="text-gray-400 hover:text-white cursor-pointer"
                                >
                                    Services
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleNavLinkClick('about')}
                                    className="text-gray-400 hover:text-white cursor-pointer"
                                >
                                    About Us
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleNavLinkClick('contact')}
                                    className="text-gray-400 hover:text-white cursor-pointer"
                                >
                                    Contact
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold mb-4">Contact Info</h4>
                        <ul className="text-gray-400 space-y-2">
                            <li>Email: sheila@mediamentions.com</li>
                            {/* <li>Phone: (555) 123-4567</li>
                            <li>Address: 123 SEO Street, Digital City</li> */}
                        </ul>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
                        <div className="flex space-x-4">
                            {[
                                { Icon: FaFacebook, label: "Facebook" },
                                { Icon: FaLinkedin, label: "LinkedIn" },
                                { Icon: FaTwitter, label: "Twitter" },
                                { Icon: FaInstagram, label: "Instagram" }
                            ].map(({ Icon, label }) => (
                                <div
                                    key={label}
                                    className="text-gray-400 hover:text-white transition-colors duration-200 transform hover:scale-110"
                                    aria-label={label}
                                >
                                    <Icon size={24} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
                    <p>&copy; {new Date().getFullYear()} MediaMentions. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
