import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.svg';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
      console.log('Scroll position:', window.scrollY, 'isScrolled:', scrolled); // Debug log
    };

    window.addEventListener('scroll', handleScroll);
    // Initial check
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavLinkClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <header className={`py-4 fixed top-0 w-full z-10 transition-all duration-300 ${isScrolled
      ? 'bg-white/90 shadow-lg backdrop-blur-sm rounded-b-2xl border-b border-gray-100'
      : 'bg-gray-50'
      }`}>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-6">
        {/* Logo section */}
        <div className="flex-1 md:flex-none transition-all duration-300 ease-in-out transform w-full md:w-auto">
          <div className="flex items-center justify-center md:justify-start transition-all duration-300 ease-in-out transform">
            <button
              onClick={scrollToTop}
              className="flex items-center space-x-2 focus:outline-none transition-transform duration-300 ease-in-out hover:scale-105"
            >
              <img src={logo} alt="MediaMentions Logo" className="h-8 w-8" />
              <span className="text-xl font-bold text-gray-800">MediaMentions</span>
            </button>
          </div>
        </div>

        {/* Navigation - centered and horizontal scrolling on mobile */}
        <div className="w-full md:w-auto mt-4 md:mt-0 overflow-x-auto">
          <nav className="flex flex-row md:flex-row items-center justify-center md:justify-start space-x-6 md:space-x-8 min-w-max px-2">
            <button onClick={() => handleNavLinkClick('home')}
              className="text-gray-600 hover:text-blue-600 px-3 py-2 whitespace-nowrap text-center rounded-lg transition-all duration-300 hover:bg-blue-50">
              Home
            </button>
            <button onClick={() => handleNavLinkClick('about')}
              className="text-gray-600 hover:text-blue-600 px-3 py-2 whitespace-nowrap text-center rounded-lg transition-all duration-300 hover:bg-blue-50">
              About Us
            </button>
            <button onClick={() => handleNavLinkClick('services')}
              className="text-gray-600 hover:text-blue-600 px-3 py-2 whitespace-nowrap text-center rounded-lg transition-all duration-300 hover:bg-blue-50">
              Services
            </button>
            <button
              onClick={() => handleNavLinkClick('contact')}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-all duration-300 whitespace-nowrap text-center"
            >
              Contact Us
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;