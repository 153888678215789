import React from 'react';
import guestPostIcon from '../images/global.png';
import brokenLinkIcon from '../images/blog.png';
import resourceLinkIcon from '../images/global.png';
import linkIcon from '../images/link.png';
import contentPromotionIcon from '../images/shout.png';
import reclamationIcon from '../images/blog.png';
import mediaMentionImage from '../images/mediaMention-1024x486.jpg';

function ServicesSection() {
  return (
    <section id="services" className="py-20 bg-gray-50 ">
      <div className="container mx-auto text-center px-4 max-w-6xl">
        <h2 className="text-3xl font-bold text-gray-800 mb-2">MediaMentions - Our Link Building Services</h2>
        <p className="text-gray-600 mb-12">Discover our comprehensive suite of link building services designed to enhance your website's authority and visibility.</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Service 1: Guest Posting */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300">
            <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
              {/* Icon Placeholder */}
              <img className="rounded-full object-cover" src={guestPostIcon} alt="Guest Posting" />
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Guest Posting</h3>
            <p className="text-gray-700">We secure high-quality guest posts on authoritative websites in your niche to drive referral traffic and boost your SEO.</p>
          </div>
          {/* Service 2: Broken Link Building */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300">
            <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
              {/* Icon Placeholder */}
              <img className="rounded-full object-cover" src={brokenLinkIcon} alt="Broken Link Building" />
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Broken Link Building</h3>
            <p className="text-gray-700">We identify broken links on relevant websites and offer your content as a replacement, creating valuable backlinks.</p>
          </div>
          {/* Service 3: Resource Link Building */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300">
            <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
              {/* Icon Placeholder */}
              <img className="rounded-full object-cover" src={resourceLinkIcon} alt="Resource Link Building" />
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Resource Link Building</h3>
            <p className="text-gray-700">We get your content featured on resource pages as a valuable asset for their audience, earning you high-quality backlinks.</p>
          </div>
          {/* Service 4: Competitor Link Analysis */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300">
            <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
              {/* Icon Placeholder */}
              <img className="rounded-full object-cover" src={guestPostIcon} alt="Competitor Link Analysis" />
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Competitor Link Analysis</h3>
            <p className="text-gray-700">We analyze your competitors' backlinks to identify opportunities for you to acquire similar links and outrank them.</p>
          </div>
          {/* Service 5: Content Promotion */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300">
            <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
              {/* Icon Placeholder */}
              <img className="rounded-full object-cover" src={guestPostIcon} alt="Content Promotion" />
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Content Promotion</h3>
            <p className="text-gray-700">We promote your content to relevant influencers and websites to increase its visibility and attract backlinks.</p>
          </div>
          {/* Service 6: Link Reclamation */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300">
            <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
              {/* Icon Placeholder */}
              <img className="rounded-full object-cover" src={reclamationIcon} alt="Link Reclamation" />
            </div>
            <p className="text-gray-700">We find mentions of your brand without a link and reach out to have them linked back to your website, recovering valuable link equity.</p>
          </div>
        </div>
        <img
    src={mediaMentionImage}
    alt="Writer typing on laptop"
    className="w-full max-w-3xl mx-auto h-auto mb-10 mt-20"
  />
      </div>
     
    </section>
  );
}

export default ServicesSection;