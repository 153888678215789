import React from 'react';
import writerImage from '../images/writer-writes-document-journalist-create-storytelling-with-laptop-hands-typing-computer-keyboard-story-writing-concept_53562-9675.jpg';

function NewComponent() {
  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-50 py-20">
      <div className="container mx-auto px-4">
        <div className="bg-white backdrop-blur-lg bg-opacity-90 rounded-2xl shadow-xl p-8 hover:shadow-2xl transition-all duration-300">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="flex flex-col items-center md:w-1/2 space-y-6">
              <div className="space-y-4">
                <span className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-blue-700 bg-clip-text text-transparent">
                  Why Guest Posts Matter
                </span>
                <h2 className="text-2xl font-extrabold text-center text-gray-800 tracking-tight">
                  LINK BUILDING
                </h2>
              </div>
              <p className="text-gray-700 text-base leading-relaxed text-justify transform hover:scale-[1.02] transition-transform duration-300">
                Guest posts are more than just engaging content - they're a proven strategy for building high-quality backlinks that boost your SEO performance. By leveraging guest posts, you can:
                <br /><br />
                • Establish authority in your industry<br />
                • Improve search engine rankings<br />
                • Drive targeted traffic to your site<br />
                • Stay ahead of competitors
                <br /><br />
                At MediaMentions, we ensure your guest posts create lasting value through strategic placement and compelling content that resonates with your target audience.
              </p>
            </div>
            <div className="md:w-1/2 transform hover:scale-105 transition-transform duration-500">
              <img
                src={writerImage}
                alt="Writer typing on laptop"
                className="w-full h-auto rounded-2xl shadow-2xl hover:shadow-3xl transition-all duration-300"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
